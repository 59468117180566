<template>
  <div>
    <div style="background-color:#FFFFFF">
      <div>
        <van-field
            :value="item.text"
            readonly
            center
            clickable
            :label="$t('显示类型')"
            :placeholder="$t('请选择显示类型')"
            @click-input="showPicker=true"
        >
          <template #button>
            <van-button @click="clean"  size="small"   type="danger"  icon="delete">
              {{$t('清空')}}
            </van-button>
          </template>>
        </van-field>
      </div>

      <div style="color: #C8C9CC;font-size: 12px;width: 100%;text-align: center;">
        {{$t('下拉列表刷新数据左滑通知信息删除')}}
      </div>
    </div>
    <van-pull-refresh v-model="loading" @refresh="getMessageList">

    <div style="min-height: 500px">
      <div v-if="list2.length>0">
        <van-list>
          <van-swipe-cell :right-width="150"  v-for="(x,key) in list2" :key=key>
            <div class="device_item">
              <div class="device_avatar">
                <van-image
                    width="50"
                    height="50"
                    :src="x.deviceInfo.avatar?x.deviceInfo.avatar:require('/static/resources/device/person.png')"
                />
              </div>
              <div class="device_info">
                <div class="device_name">
                  <div>
                    <span  :style="{color:'#969799'}">{{x.deviceInfo.name}} - {{x.imei}}</span>
                    &nbsp; <van-tag v-if="!x.read" :type="'success'">
                    新
                  </van-tag>
                  </div>
<!--                  <div style="padding-right: 5px">-->
<!--                    {{x.eventName}}-->
<!--                  </div>-->
                </div>
                <div style="padding:5px 0px;color:#323233;font-size: 16px">
                  {{x.remark}}
                </div>
                <div style="color: #878B8E">
                  {{x.eventName}}  {{x.timeStr}}  {{x.groupName}}
                </div>
<!--                <div v-if="x.groupName">-->
<!--                  {{x.groupName}}-->
<!--                </div>-->
              </div>
            </div>
            <template #right>
              <div class="ac" style="height: 100%">
                <van-button @click="del(x.id)"  type="danger" >{{$t('删除')}}</van-button>
<!--                <van-button @click="editEvent(x.imei)" type="default" >编辑</van-button>-->
                <!--              <van-button @click="goActionItem(x)" type="primary" >更多</van-button>-->
              </div>
            </template>
            <van-divider style="margin: 0"/>
          </van-swipe-cell>

        </van-list>


      </div>

      <div v-else style="color:#878B8E;font-size:15px;margin-top: 145px;height:500px;text-align: center;">{{loading?"":$t("暂无数据")}}</div>

    </div>
    </van-pull-refresh>
    <van-action-sheet v-model="showPicker" :actions="eventList2" @select="onConfirm" :cancel-text="$t('取消')"
                      close-on-click-action />
  </div>
</template>
<script>
import {
  Toast,
  Popup,
  SwipeCell,
  Field,
  Button,
  Tag,
  Image,
  List,
  Picker,
  Cascader,
  Row,
  Col,
  Divider,
  Sticky,
  Dialog,
    PullRefresh,
    ActionSheet
} from "vant";
import {noticeClean, noticeDel, noticeList} from "../../../api/message";
import {readCommonParam} from "../../../util/session-storage-utils";

export default {
  name: "index",
  components: {
    [Popup.name]: Popup,
    [SwipeCell.name]:SwipeCell,
    [Field.name]:Field,
    [Button.name]:Button,
    [Tag.name]:Tag,
    [Image.name]:Image,
    [List.name]:List,
    [Picker.name]:Picker,
    [Popup.name]:Popup,
    [Cascader.name]:Cascader,
    [Row.name]:Row,
    [Col.name]:Col,
    [Divider.name]:Divider,
    [Sticky.name]:Sticky,
    [Dialog.name]:Dialog,
    [PullRefresh.name]:PullRefresh,
    [ActionSheet.name]:ActionSheet,
  },
  data: () => ({
    list: [],
    eventList: [],
    item:{},
    countMap:{},
    loading:true,
    showPicker:false
  }),
  created(){
    readCommonParam(this)
    this.eventList = [{id:0,text:this.$t('全部')},
      {id:60,text:this.$t('SOS报警')},
      {id:43,text:this.$t('围栏报警')},
      {id:42,text:this.$t('位移报警')},
      {id:52,text:this.$t('静止报警')},
      {id:41,text:this.$t('低电报警')},
      {id:40,text:this.$t('震动报警')},
      {id:44,text:this.$t('超速报警')},
      {id:13,text:this.$t('上线报警')},
      {id:11,text:this.$t('离线报警')},
      {id:50,text:this.$t('停止充电')},
      {id:51,text:this.$t('开始充电')}]
    this.item = {id:0,text:this.$t('全部')};

    document.title = this.$t("通知列表");
    this.getMessageList();
  },
  computed:{
    eventList2(){
        let eventList2 = [];
        for (let i in this.eventList){
          let event = this.eventList[i];
          let count = this.countMap[event.id];
          if(!count){
            count = 0;
          }
          eventList2.push({...event,name:event.text + "("+ count +")"})
        }
        return eventList2;
    },
    list2(){
      if(this.item.id == 0){
        return this.list;
      }
      let list2 = [];
      for (const i in this.list) {
          let item = this.list[i];
          if(item.eventId == this.item.id){
            list2.push(item);
          }
      }
      return list2;
    },

  },
  methods:{
    getMessageList(){
      this.loading = true;
      Toast.loading({message:this.$t("加载中"),duration:0,position:"top"})

      noticeList({eventId:this.item.id }).then((res) => {
        Toast.clear()
        let data = res.data;
        if(data){
          this.list = data;
          let countMap = {};
          let total = 0;
          for (let key in data){
            let item = data[key];
            let count = countMap[item.eventId];
            if(!count){
              count = 0;
            }
            ++count
            countMap[item.eventId] = count;
            total++;
          }
          countMap[0] = total;
          this.countMap = countMap;
        }

      }).finally( () => {

        this.loading = false
      })
    },
    clean(){
      Dialog.confirm({
        title: this.$t('确认清空数据?'),
        message: this.$t('数据删除后将无法恢复请谨慎操作'),
      })
          .then(() => {
            Toast.loading({message:this.$t("处理中"),duration:0,position:"top"})
            noticeClean().then(() => {
              Toast.clear();
              this.list=[];
              this.countMap = {};
            })
          })
          .catch(() => {
            // on cancel
          });


    },
    del(id){
      Toast.loading({message:this.$t("处理中"),duration:0,position:"top"})
      noticeDel([id]).then(() => {
        for (const i in this.list) {
            let item = this.list[i];
            if(item.id == id){
              this.list.splice(i,1);
              break;
            }
        }
        Toast.clear();
      })
    },
    onConfirm(item){
      this.showPicker = false;
      this.item = item;
      // this.getMessageList();
    },

  }
};
</script>


<style lang="less" scoped>
.device_item{
  width: 100%;
  display: flex;
  align-items:center;
  padding: 10px;
  font-size: 13px;
  .device_info{
    padding: 0 20px;
    width: 100%;
    .device_name{
      display: flex;
    }
  }
}
</style>
