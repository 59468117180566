import request from '@/util/request'
export function noticeList(data) {
    return request({
        url: '/notice/list',
        method: 'post',
        data:data
    })
}
export function noticeClean() {
    return request({
        url: '/notice/clean',
        method: 'DELETE',
    })
}

export function noticeDel(idList) {
    return request({
        url: '/notice/del',
        method: 'post',
        data: {idList}
    })
}
